import { Button, Card, List, Skeleton, Typography } from 'antd';
import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import worldImage from '../../../assets/world-sm.jpg';
import settings from '../../../settings';
import { shuffle } from '../../../shared/utils';
import { useWindowDimensions } from '../../../shared/window-dimensions';
import { FundingLevels } from './constants';

const { Text, Title, Paragraph } = Typography;

function PeopleGroupList({
  peopleGroups,
  loading,
  next,
  resetFilters,
  hasNextPage,
  oneRow = false,
  randomize = false,
}) {
  const [numShown, setNumShown] = useState(settings.pageSize);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dimensions = useWindowDimensions();
  const columns = useMemo(() => {
    if (dimensions.width > settings.screenSizes.xl1200) {
      return 4;
    }
    if (dimensions.width > settings.screenSizes.lg992) {
      return 4;
    }
    if (dimensions.width > settings.screenSizes.md600) {
      return 3;
    }
    if (dimensions.width > settings.screenSizes.sm) {
      return 2;
    }
    return 1;
  }, [dimensions]);
  const items = useMemo(() => {
    let _items = peopleGroups;
    if (oneRow) {
      _items = _items.slice(0, columns);
    } else {
      _items = _items.slice(0, numShown);
    }
    if (randomize) {
      _items = shuffle(_items);
    }
    return _items;
  }, [oneRow, columns, peopleGroups, numShown, randomize]);
  return (
    <>
      {(!!peopleGroups.length || !loading) && (
        <List
          grid={{
            gutter: 16,
            column: columns,
          }}
          dataSource={items}
          renderItem={(pg) => {
            const pgName = `${pg.nameAcrossCountries} People`;
            const religion = `Religion: ${pg.primaryReligion}`;
            const pop =
              pg.population &&
              `${Math.round(pg.population).toLocaleString(locale)} People`;
            const region = pg.region;
            const numAdoptions = `${pg.numAdoptionsAvailable} ${
              pg.numAdoptionsAvailable > 1 ? 'Missionaries' : 'Missionary'
            } Available`;
            const suggestedAmount =
              FundingLevels[pg.suggestedAdoptionLevel] &&
              FundingLevels[pg.suggestedAdoptionLevel].defaultMonthlyAmount;
            const suggested = `Suggested Gift: $${suggestedAmount} Monthly`;
            return (
              <List.Item>
                <Link to={`/partner/adopt-a-upg/${pg._id}${location.search}`}>
                  <Card
                    cover={
                      <div style={{ position: 'relative' }}>
                        <ImageWithDefault
                          imageUrl={pg.primaryThumbnailUrl}
                          fallbackUrl={worldImage}
                          alt={pgName}
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            aspectRatio: '1/1',
                          }}
                        />
                        <div
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                            fontSize: 12,
                            background: settings.colors.primary,
                            textAlign: 'center',
                            padding: '8px 8px',
                          }}
                        >
                          <Text style={{ color: 'white' }}>{suggested}</Text>
                        </div>
                      </div>
                    }
                    style={{
                      maxWidth: 300,
                      margin: 'auto',
                    }}
                  >
                    <Card.Meta
                      description={
                        <>
                          <div className="right" style={{ marginBottom: 0 }}>
                            <Title level={5} ellipsis={{ tooltip: pgName }}>
                              {pgName}
                            </Title>
                          </div>
                          <div className="right">
                            <Text ellipsis={{ tooltip: region }}>{region}</Text>
                          </div>
                          {pop && (
                            <div className="right">
                              <Text ellipsis={{ tooltip: pop }}>{pop}</Text>
                            </div>
                          )}
                          <div className="row2">
                            <Text ellipsis={{ tooltip: religion }}>
                              {religion}
                            </Text>
                          </div>
                          <div className="right" style={{ marginTop: 8 }}>
                            <Text ellipsis={{ tooltip: numAdoptions }}>
                              {numAdoptions}
                            </Text>
                          </div>
                          <div style={{ textAlign: 'center', marginTop: 8 }}>
                            <Button
                              type="primary"
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: 700,
                                height: 32,
                                paddingTop: 4,
                                paddingBottom: 4,
                                minWidth: 100,
                              }}
                            >
                              Give
                            </Button>
                          </div>
                        </>
                      }
                    />
                  </Card>
                </Link>
              </List.Item>
            );
          }}
          locale={{
            emptyText: resetFilters && (
              <>
                <Paragraph style={{ fontSize: 16, marginBottom: 32 }}>
                  {`There are currently no available UPG adoptions with these specifications.`}
                </Paragraph>
                <div>
                  <Button type="primary" onClick={resetFilters}>
                    View available Unreached People Group Adoptions
                  </Button>
                </div>
              </>
            ),
          }}
          rowKey="_id"
          loadMore={
            next && (
              <div style={{ textAlign: 'center' }}>
                {peopleGroups.length > numShown && (
                  <div className="bottom-actions">
                    <Button
                      onClick={() => setNumShown(numShown + settings.pageSize)}
                    >
                      Show More
                    </Button>
                  </div>
                )}
                {peopleGroups.length <= numShown && hasNextPage && (
                  <div className="bottom-actions">
                    <Button onClick={next}>Load More</Button>
                  </div>
                )}
              </div>
            )
          }
        />
      )}
      {!peopleGroups.length && loading && (
        <List
          grid={{
            gutter: 16,
            column: columns,
          }}
          dataSource={oneRow ? Array(6).fill(0) : [0, 1, 2, 3, 4, 5, 6, 7, 8]}
          renderItem={() => {
            return (
              <List.Item>
                <Link>
                  <Card style={{ width: '100%' }}>
                    <Skeleton avatar active />
                  </Card>
                </Link>
              </List.Item>
            );
          }}
        />
      )}
      <style jsx global>{`
        .ant-card-meta-detail {
          width: 100%;
        }
      `}</style>
    </>
  );
}

export default PeopleGroupList;
