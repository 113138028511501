import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { PeopleGroupRoles } from '@aims/shared/people-groups/constants';
import { Card, List, Typography } from 'antd';
import humanize from 'humanize-number';
import React from 'react';
import { Link } from 'react-router-dom';
import worldImage from '../../assets/world-sm.jpg';
import settings from '../../settings';

const { Title, Text } = Typography;

function MyPeopleGroupCard({ item }) {
  const { roles, peopleGroup } = item;
  const { nameAcrossCountries, country } = peopleGroup;
  const name = `${nameAcrossCountries} - ${country}`;

  return (
    <List.Item>
      <Link to={`/upgs/${peopleGroup._id}/info`}>
        <Card
          className="pg-card"
          cover={
            <div className="image-container">
              <ImageWithDefault
                imageUrl={peopleGroup.primaryThumbnailUrl}
                fallbackUrl={worldImage}
                alt={name}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          }
        >
          {roles.map((role, index) => (
            <React.Fragment key={index}>
              <Text
                style={{
                  color: settings.colors.primary,
                  margin: 0,
                  fontSize: 14,
                  letterSpacing: 0.5,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                }}
              >
                {PeopleGroupRoles[role]}
              </Text>
              <br />
            </React.Fragment>
          ))}
          <Title level={3} style={{ marginBottom: 0, marginTop: 0 }}>
            {name}
          </Title>
          <div className="subtitle">
            <Text style={{ color: settings.colors.darkGray }}>
              {peopleGroup._id}
            </Text>
          </div>
          {peopleGroup.population && (
            <div>
              <Text>{`Population: ${humanize(peopleGroup.population)}`}</Text>
            </div>
          )}
          <div>
            <Text>{`Primary Religion: ${peopleGroup.primaryReligion}`}</Text>
          </div>
          <div>
            <Text>{`Language: ${peopleGroup.primaryLanguage}`}</Text>
          </div>
          <div></div>
        </Card>
      </Link>
      <style jsx>{`
        .subtitle {
          margin-bottom: 12px;
        }
        .image-container {
          overflow: hidden;
          aspect-ratio: 1/1;
        }
      `}</style>
      <style jsx global>{`
        .pg-card {
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
        }
      `}</style>
    </List.Item>
  );
}

export default MyPeopleGroupCard;
