import { HolisticProjectRequestItems } from '@aims/shared/reports';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
// import DeleteReportModal from './DeleteReportModal';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import {
  ReportApprovalStatuses,
  ReportTypes,
} from '@aims/shared/reports/constants';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useAccount from '../../../shared/use-account';
import useProfile from '../../../shared/use-profile';
import useMyPeopleGroups from '../../people-groups/use-my-pgs';
import DeleteReportButton from './DeleteReportButton';
import {
  createHolisticProposalMutation,
  submitHolisticProposalMutation,
  unsubmitHolisticProposalMutation,
  updateHolisticProposalMutation,
} from './constants';
import useReport from './use-report';

const { Title, Text } = Typography;

const queryId = 'editProposal';

function EditProposal() {
  const { reportId } = useParams();
  const account = useAccount();
  const { myPgs } = useMyPeopleGroups();

  const {
    report,
    loading: loading1,
    refetch,
  } = useReport(reportId, queryId, false);

  const [form] = Form.useForm();
  const finishSubForm = useRef();
  const shouldSubmitReport = useRef(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [updateReport] = useMutation(updateHolisticProposalMutation);
  const [createReport] = useMutation(createHolisticProposalMutation);
  const [submitReport] = useMutation(submitHolisticProposalMutation);
  const [unsubmitReport] = useMutation(unsubmitHolisticProposalMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Proposal ...';
      } else if (shouldSubmitReport.current === 'resubmit') {
        msg = 'Re-submitting Proposal ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Proposal ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      const { notes, ..._values } = __values;
      try {
        const reportDetails = finishSubForm.current(_values);
        if (
          report &&
          ['unsubmit', 'resubmit'].includes(shouldSubmitReport.current)
        ) {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Success',
            description: 'Proposal unsubmitted.',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: ReportTypes.HOLISTIC_REQ.key,
                contactId: account._id,
                notes,
                ...reportDetails,
              },
            },
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: account._id,
                reportType: ReportTypes.HOLISTIC_REQ.key,
                notes,
                ...reportDetails,
              },
            },
          });
        }
        if (['submit', 'resubmit'].includes(shouldSubmitReport.current)) {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Success',
            description: 'Proposal submitted.',
          });
          history.push(`/holistic-proposals/view/${reportId}`);
        } else {
          notification.success({
            message: 'Success',
            description: 'Proposal saved.',
          });
          refetch();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      account,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
      refetch,
    ],
  );

  const saveDraft = useCallback(async () => {
    const __values = form.getFieldsValue();
    setSaveLoading('Saving Draft ...');
    const { notes, ..._values } = __values;
    try {
      const reportDetails = finishSubForm.current(_values);
      if (report) {
        await updateReport({
          variables: {
            report: {
              _id: reportId,
              reportType: ReportTypes.HOLISTIC_REQ.key,
              contactId: account._id,
              notes,
              ...reportDetails,
              peopleGroupIds: reportDetails.peopleGroupIds.filter((p) => p),
              responsiblePersons: reportDetails.responsiblePersons.filter(
                (p) => Object.keys(p).length > 0,
              ),
              supportedFieldWorkers: reportDetails.supportedFieldWorkers.filter(
                (p) => Object.keys(p).length > 0,
              ),
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Draft saved.',
        });
      } else {
        await createReport({
          variables: {
            report: {
              _id: reportId,
              contactId: account._id,
              reportType: ReportTypes.HOLISTIC_REQ.key,
              notes,
              ...reportDetails,
              peopleGroupIds: reportDetails.peopleGroupIds.filter((p) => p),
              responsiblePersons: reportDetails.responsiblePersons.filter(
                (p) => Object.keys(p).length > 0,
              ),
              supportedFieldWorkers: reportDetails.supportedFieldWorkers.filter(
                (p) => Object.keys(p).length > 0,
              ),
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Draft saved.',
        });
      }
      refetch();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your report.',
      });
    }
    setSaveLoading(false);
  }, [reportId, createReport, account, report, updateReport, form, refetch]);

  const title = 'Holistic Project Proposal';

  const loading = loading1 || saveLoading;
  const showDelete = report && !report.submittedAt;
  const profile = useProfile();

  return (
    <CSPage title="AIMS.org | Edit Holistic Project Proposal">
      <CSPageHeader
        titleComponent={null}
        title={title}
        parents={[
          {
            label: 'Holistic Project Proposals',
            link: `/holistic-proposals`,
          },
        ]}
      />
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="EditReportForm"
        form={form}
        style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
      >
        {loading1 ? (
          <>
            <Title level={3} style={{ textAlign: 'center' }}>
              {title}
            </Title>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
          </>
        ) : (
          <HolisticProjectRequestItems
            _id={reportId}
            report={report}
            loading={loading}
            form={form}
            finishSubForm={finishSubForm}
            pgConnections={myPgs}
            regionalCoordId={profile?.myContact?._id}
            showTitle
          />
        )}
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {(!report ||
              report?.status === ReportApprovalStatuses.DRAFT.key) && (
              <>
                <Button
                  key="draft"
                  type="dashed"
                  loading={loading}
                  htmlType="button"
                  onClick={saveDraft}
                >
                  Save Draft
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = 'submit';
                    form.submit();
                  }}
                >
                  Submit Proposal
                </Button>
              </>
            )}
            {report?.status === ReportApprovalStatuses.PENDING.key && (
              <Button
                key="unsubmit"
                type="dashed"
                loading={loading}
                htmlType="button"
                onClick={() => {
                  shouldSubmitReport.current = 'unsubmit';
                  form.submit();
                }}
              >
                Save Changes & Unsubmit Proposal
              </Button>
            )}
            {report?.status === ReportApprovalStatuses.HOLD.key && (
              <Button
                key="resubmit"
                type="primary"
                loading={loading}
                htmlType="button"
                onClick={() => {
                  shouldSubmitReport.current = 'resubmit';
                  form.submit();
                }}
              >
                Save Changes & Re-Submit Report
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {showDelete && <DeleteReportButton report={report} />}
      <Modal open={saveLoading} footer={null} closable={false} width={300}>
        <div style={{ textAlign: 'center', padding: 24 }}>
          <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
          <Title level={4}>{saveLoading}</Title>
        </div>
      </Modal>
    </CSPage>
  );
}

export default EditProposal;
