import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { getNumberValue } from '@aims/shared/shared/utils';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Radio,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import MyPhotoCircle from '../../../components/MyPhotoCircle';
import { updatePeopleGroupAction } from '../../../redux-store/people-groups-store';
import settings from '../../../settings';
import useQueryParams from '../../../shared/use-query-params';
import CatalogSteps from './CatalogSteps';
import WeWillMatch from './WeWillMatch';
import {
  FundingLevels,
  aUpgFormAllAdoptionsQuery,
  extractPublicAdoptions,
  extractPublicPeopleGroup,
  publicPeopleGroupQuery,
} from './constants';
import DateFormItem from '@aims/shared/components/DateFormItem';

const { Text, Title, Paragraph } = Typography;

const queryId = 'adoptionAUpgForm';

function AdoptAUpgForm({ pgId, step, title }) {
  const [form] = Form.useForm();

  const queryParams = useQueryParams();
  const history = useHistory();

  const { loading: pgLoading } = useOneM1({
    variables: { _id: pgId },
    query: publicPeopleGroupQuery,
    extract: extractPublicPeopleGroup,
    updateAction: updatePeopleGroupAction,
    skip: !pgId || pgId === '__aims__',
    showError: true,
    queryId,
  });
  const peopleGroup = useSingleSomethingA10('peopleGroups', pgId, queryId);

  const filters = useRef({
    peopleGroupId: pgId,
  });
  const sortBy = useRef();

  const { data: adoptions, loading: loading2 } = useManyRemoteM1({
    query: aUpgFormAllAdoptionsQuery,
    extract: extractPublicAdoptions,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    queryId,
    fetchPolicy: 'cache-first',
    skip: !pgId || pgId === '__aims__',
  });
  const loading = pgLoading || loading2;

  const fundingLevels = useMemo(() => {
    if (pgId === '__aims__') {
      return {
        [FundingLevels.ADOPTER_LV1.key]: {
          ...FundingLevels.ADOPTER_LV1,
          adoptionId: '__aims__',
        },
        [FundingLevels.ADOPTER_LV2.key]: {
          ...FundingLevels.ADOPTER_LV2,
          adoptionId: '__aims__',
        },
      };
    }
    return (adoptions || []).reduce((prev, curr) => {
      if (!prev[curr.level]) {
        prev[curr.level] = {
          ...FundingLevels[curr.level],
          adoptionId: curr._id,
        };
      }
      return prev;
    }, {});
  }, [adoptions, pgId]);

  const handleAdopt = useCallback(
    (values) => {
      const link = `/partner/adopt-a-upg/${pgId}/adopt?${queryString.stringify({
        ...values,
        adoptionId: fundingLevels[values.level].adoptionId,
        startDate: values.startDate,
      })}`;
      history.push(link);
    },
    [pgId, history, fundingLevels],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const pgInfo = useMemo(() => {
    if (peopleGroup) {
      return {
        // pgName: `${peopleGroup.nameAcrossCountries} / ${peopleGroup.region}`,
        pgName: `${peopleGroup.nameAcrossCountries} People`,
        region: peopleGroup.region,
        // lang: `Language: ${peopleGroup.primaryLanguage}`,
        religion: `Religion: ${peopleGroup.primaryReligion}`,
        pop:
          peopleGroup.population &&
          `${Math.round(peopleGroup.population).toLocaleString(locale)} People`,
        numAdoptions: `${peopleGroup.numAdoptionsAvailable} ${
          peopleGroup.numAdoptionsAvailable > 1 ? 'Missionaries' : 'Missionary'
        } Available`,
        suggestedLevel:
          peopleGroup.suggestedAdoptionLevel &&
          `Suggested Gift: ${
            FundingLevels[peopleGroup.suggestedAdoptionLevel].label
          }`,
      };
    }
    return undefined;
  }, [peopleGroup, locale]);

  useEffect(() => {
    if (queryParams && (queryParams.level || queryParams.recurring)) {
      let level = getNumberValue(queryParams.level);
      if (level > 0) {
        level = Object.values(FundingLevels).find((v) => v.value === level);
        if (level) {
          level = level.key;
        }
      }
      let recurring;
      if (['recurring', 'one-time'].includes(queryParams.recurring)) {
        recurring = queryParams.recurring;
      }
      form.setFieldsValue({
        level: level || undefined,
        recurring,
      });
    }
  }, [queryParams, form]);

  useEffect(() => {
    if (peopleGroup && peopleGroup.suggestedAdoptionLevel) {
      if (form.getFieldValue('level') === undefined) {
        form.setFieldsValue({
          level: peopleGroup.suggestedAdoptionLevel,
        });
      }
    }
  }, [peopleGroup, form, queryParams]);

  return (
    <div>
      <CatalogSteps step={step} title={title} />
      <Title level={2} style={{ textAlign: 'center' }}>
        Sponsor a Native Missionary to Minister Among this UPG
      </Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 16,
          marginBottom: 24,
        }}
      >
        <div className="pg-card">
          {pgLoading && <Skeleton avatar active />}
          {!peopleGroup && (
            <div>
              <Title
                level={3}
                style={{ marginBottom: 12, textAlign: 'center' }}
              >
                People Group
              </Title>
              <WeWillMatch />
            </div>
          )}
          {peopleGroup && pgInfo && (
            <>
              <div className="pg-photo">
                <MyPhotoCircle
                  imageUrl={peopleGroup.primaryThumbnailUrl}
                  initials={pgInfo.pgName}
                  diameter={160}
                  color={settings.colors.yellow}
                  textStyle={{ color: 'white' }}
                />
              </div>
              <div>
                <div className="right">
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: settings.colors.primary,
                      whiteSpace: 'normal',
                    }}
                  >
                    {pgInfo.pgName}
                  </Text>
                </div>
                <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: pgInfo.region }}
                  >
                    {pgInfo.region}
                  </Text>
                </div>
                {pgInfo.pop && (
                  <div className="right">
                    <Text
                      style={{ width: 224 }}
                      ellipsis={{ tooltip: pgInfo.pop }}
                    >
                      {pgInfo.pop}
                    </Text>
                  </div>
                )}
                {/* <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: pgInfo.lang }}
                  >
                    {pgInfo.lang}
                  </Text>
                </div> */}
                <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: pgInfo.religion }}
                  >
                    {pgInfo.religion}
                  </Text>
                </div>
                <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: pgInfo.numAdoptions }}
                  >
                    {pgInfo.numAdoptions}
                  </Text>
                </div>
                <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: pgInfo.suggestedLevel }}
                  >
                    {pgInfo.suggestedLevel}
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div style={{ overflow: 'auto', width: '100%' }}>
        <div className="adoption-levels">
          <div className="adoption-levels-item">
            <Title level={3} style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>
              Adoption Level 1
            </Title>
            <Paragraph style={{ marginBottom: 16 }}>
              $30 a month or $360 annually
            </Paragraph>
            <Timeline>
              <Timeline.Item
                color="#ad8b00"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Missionary <br /> (partial coverage)
              </Timeline.Item>
              <Timeline.Item
                color="#ad8b00"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Leadership <br /> (partial coverage)
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Quarterly Updates
              </Timeline.Item>
            </Timeline>
          </div>
          <div className="adoption-levels-item">
            <Title level={3} style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>
              Adoption Level 2
            </Title>
            <Paragraph style={{ marginBottom: 16 }}>
              $100 a month or $1200 annually
            </Paragraph>
            <Timeline>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Missionary
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Leadership
              </Timeline.Item>
              <Timeline.Item
                color="#ad8b00"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Quarterly Updates
              </Timeline.Item>
              <Timeline.Item
                color="#ad8b00"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Holistic Project
              </Timeline.Item>
            </Timeline>
          </div>
          <div className="adoption-levels-item">
            <Title level={3} style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>
              Adoption Level 3
            </Title>
            <Paragraph style={{ marginBottom: 16 }}>
              $300 a month or $3600 annually
            </Paragraph>
            <Timeline>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Missionary
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Native Leadership
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Quarterly Updates
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Holistic Project
              </Timeline.Item>
              <Timeline.Item
                color="#237804"
                dot={<img src={orangeCheck} style={{ height: 24 }} />}
              >
                Missions Training
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      </div> */}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleAdopt}
        style={{
          maxWidth: 600,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Form.Item
          name="recurring"
          rules={[{ required: true, message: 'This field is required' }]}
          initialValue={'recurring'}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="recurring">Recurring Monthly</Radio.Button>
            <Radio.Button value="one-time">One Time</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.recurring !== curValues.recurring
          }
          noStyle
        >
          {({ getFieldValue }) => (
            <Form.Item
              label="Gift Amount"
              name="level"
              rules={[
                { required: true, message: 'Please select an gift amount' },
              ]}
            >
              <Select
                disabled={loading}
                placeholder="Select an adoption level ..."
              >
                {Object.values(fundingLevels)
                  .filter((l) => l.value > 0)
                  .map((level) => (
                    <Select.Option key={level.key} value={level.key}>
                      {level.longLabel(
                        getFieldValue('recurring') === 'recurring',
                      )}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
        {/* <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.level !== curValues.level ||
            prevValues.recurring !== curValues.recurring
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const levelKey = getFieldValue('level');
            const recurring = getFieldValue('recurring');
            const level = AdoptionLevels[levelKey];
            if (level === undefined || recurring === undefined) {
              return <Paragraph />;
            }
            const amount = level.defaultMonthlyAmount;
            let message;
            if (recurring === 'recurring') {
              message = `${displayMoney(amount, 0)} / Month Recurring Donation`;
            } else {
              message = `${displayMoney(amount * 12, 0)} One-Time Donation`;
            }
            return (
              <Paragraph
                style={{
                  textAlign: 'center',
                  fontSize: 16,
                }}
              >
                {message}
              </Paragraph>
            );
          }}
        </Form.Item> */}
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.level !== curValues.level ||
            prevValues.recurring !== curValues.recurring
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('recurring') === 'recurring' && (
              <DateFormItem
                name="startDate"
                rules={[{ required: true, message: 'Please select a date' }]}
                label="First Donation"
                initialValue={new Date().toISOString()}
                format={'MMMM D, YYYY'}
              />
            )
          }
        </Form.Item>
        <Divider />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/partner/adopt-a-upg" style={{ marginLeft: -12 }}>
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
            >
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
      <style jsx>{`
        .adoption-levels {
          display: flex;
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
          min-width: 600px;
          padding-left: 16px;
          padding-right: 16px;
        }
        .adoption-levels-item {
          flex: 1;
        }
        .adoption-levels-item:not(:last-child) {
          margin-right: 32px;
        }
        .pg-card {
          // border-radius: 8px;
          display: flex;
          padding: 24px;
          margin-bottom: 24px;
          background: white;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 3px 0 rgb(0 0 0 / 60%);
        }
        .pg-photo {
          margin-right: 24px;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .pg-card {
            flex-direction: column;
          }
          .right {
            text-align: center;
          }
          .pg-photo {
            margin-bottom: 16px;
            margin-right: 0px;
          }
        }
      `}</style>
    </div>
  );
}

export default AdoptAUpgForm;
