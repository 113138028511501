import { ViewHolisticQuarterlyReport } from '@aims/shared/reports';
import { Skeleton, Space } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useHolisticProjectForCoord from '../use-holistic-project-for-coord';
import useReport from './use-report';

const queryId = 'viewHolisticQuarterlyReport';

function ViewHolisticQuarterlyReportPage() {
  const { projectId, reportId } = useParams();

  const { loading: loading1, data: holisticProject } =
    useHolisticProjectForCoord(projectId, queryId);

  const { report, loading: loading2 } = useReport(reportId, queryId, true);

  return (
    <CSPage title="AIMS.org | View Quarterly Holistic Project Report">
      <CSPageHeader
        titleComponent={null}
        title="View Holistic Progress Report"
        parents={[
          {
            label: 'My Projects',
            link: `/holistic-projects`,
          },
          {
            label: holisticProject?.projectName,
            link: `/holistic-projects/${projectId}/reports`,
          },
        ]}
      />
      {(loading1 || loading2) && (
        <Space direction="vertical">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Space>
      )}
      {report && holisticProject && (
        <div style={{ maxWidth: 800, marginRight: 'auto', marginLeft: 'auto' }}>
          <ViewHolisticQuarterlyReport
            report={report}
            holisticProject={holisticProject}
          />
        </div>
      )}
    </CSPage>
  );
}

export default ViewHolisticQuarterlyReportPage;
